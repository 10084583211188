import styled from "styled-components";

export const MessageVideoStyle = styled.div`
  /* background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(${(props) => props.src}); */
  max-height: 320px;
  max-width: 320px;
  padding: 10px;
  border-radius: 5px;

  .date {
    font-size: 10px;
    margin-top: 26px;
    text-align: right;
    margin-left: 5px;
    font-family: roboto;
  }
`;
