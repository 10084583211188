//import io from "socket.io-client";

import { apiHttp as httpApi } from "../../services/api-http";
import { hoursOfOut } from "../../store/hours/action";

import {
  positionQueue,
  addQueue,
  addIdConversation,
  //socketId,
} from "../../store/user/actions";
import store from "../../store/";

export default function entrouFilaCliente(event) {
  let id = event.conversa?._id ? event.conversa._id : '';
  let queue = event.conversa?.fila ? event.conversa.fila : '';

  store.dispatch(hoursOfOut(event.foraHorario ? event.foraHorario : false));

  store.dispatch(addQueue(queue));
  store.dispatch(addIdConversation(id));

    httpApi
      .get(`/conversa/posicaoDaConversaNaFila/${queue}/${id}`)
      .then((response) => {
        store.dispatch(positionQueue(response.data.posicao));
      })
      .catch((err) => {
        console.log("err: ", err);
      });
}
