import styled from 'styled-components';

export const RatingBox = styled.div`
  height: 35px;
  width: 35px;
  min-width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  tex-align: center;
`;



