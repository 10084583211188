export function loginAction(name,email,telefone) {
  return {
    type: '@login/LOGIN',
    payload: {
      name,
      email,
      telefone,
      logged: true
    }
  };
}

export function addQueue(queue) {
  return {
    type: '@login/ADD_QUEUE',
    payload: {
      queue
    }
  };
}

export function addIdConversation(idConversa) {
  return {
    type: '@login/ADD_ID_CONVERSATION',
    payload: {
      idConversa
    }
  };
}

export function positionQueue(positionQueue) {
  return {
    type: '@login/POSITION_QUEUE',
    payload: {
      positionQueue
    }
  };
}

export function socketId(socketId) {
  return {
    type: '@login/SOCKET_ID',
    payload: {
      socketId
    }
  };
}