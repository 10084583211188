import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./App.css";
import "./App.scss";
import { G4Container, G4LogoOpen } from "./components/styled";
import "./config/ReactotronConfig";
import Header from "./components/header/Header";
import Login from "./pages/login/Login";
import Queue from "./pages/queue/Queue";
import Wait from "./pages/wait/Wait";
import Conversation from "./pages/conversation/Conversation";
import CustumerExperienceSurvey from "./pages/customer-experience-survey/CustumerExperienceSurvey";
import End from "./pages/end/End";
import { FaComments } from "react-icons/fa";

import "./services/get-config-chat";

const LOGIN = "Login";
const QUEUE = "Queue";
const WAIT = "Wait";
const CONVERSATION = "Conversation";
const CUSTOMER_EXPERIENCE = "CustomerExperience";
const END = "End";

const App = () => {
  const page = useSelector((state) => state.page);
  const config = useSelector((state) => state.config);

  const [chatOpen, setChatOpen] = useState(false);

  function openChat() {
    setChatOpen(true);
  }

  function minimize(value) {
    setChatOpen(value);
  }

  return (
    <div style={{ zIndex: 20000 }}>
      {!chatOpen && config.color !== "" && (
        <G4LogoOpen
          color={config.color}
          style={{ zIndex: 20000 }}
          onClick={() => {
            openChat();
          }}
        >
          <FaComments
            onClick={() => {
              openChat();
            }}
            size="40"
            color="#fff"
          />
        </G4LogoOpen>
      )}

      <G4Container style={{ zIndex: 20000 }} size={chatOpen}>
        <Header minimize={minimize} />
        {page === LOGIN ? <Login /> : ""}
        {page === QUEUE ? <Queue /> : ""}
        {page === WAIT ? <Wait /> : ""}
        {page === CONVERSATION ? (
          <Conversation size={chatOpen ? "" : "none"} />
        ) : (
          ""
        )}
        {page === CUSTOMER_EXPERIENCE ? <CustumerExperienceSurvey /> : ""}
        {page === END ? <End /> : ""}
      </G4Container>
    </div>
  );
};

export default App;
