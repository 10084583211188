import { produce } from "immer";

const INITIAL_STATE = {
  timeBegin: "",
  timeEnd: "",
  color: "lightgrey",
  useBot: false,
  botName: ""
};

export default function page(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@config/ADD_CONFIG":
      return produce(state, (draft) => {
        draft.color = action.payload.color;
        draft.useBot = action.payload.useBot;
        draft.botName = action.payload.botName;
      });
    default:
      return state;
  }
}
