import { makeStyles } from '@material-ui/core/styles';

export const satisfactionStyles = makeStyles({
  iconFilled: {},
  iconFilled1: { color: '#d9534f' },
  iconFilled2: { color: '#d9534f' },
  iconFilled3: { color: '#d9534f' },
  iconFilled4: { color: '#f0ad4e' },
  iconFilled5: { color: '#5cb85c' },
  iconHover: {},
  iconHover1: { color: '#d9534f' },
  iconHover2: { color: '#d9534f' },
  iconHover3: { color: '#d9534f' },
  iconHover4: { color: '#f0ad4e' },
  iconHover5: { color: '#5cb85c' }
});

export const npsStyles = makeStyles({
  iconFilled: {},
  iconFilled1: { color: '#d9534f' },
  iconFilled2: { color: '#d9534f' },
  iconFilled3: { color: '#d9534f' },
  iconFilled4: { color: '#d9534f' },
  iconFilled5: { color: '#d9534f' },
  iconFilled6: { color: '#d9534f' },
  iconFilled7: { color: '#f0ad4e' },
  iconFilled8: { color: '#f0ad4e' },
  iconFilled9: { color: '#5cb85c' },
  iconFilled10: { color: '#5cb85c' },
  iconHover: {},
  iconHover1: { color: '#d9534f' },
  iconHover2: { color: '#d9534f' },
  iconHover3: { color: '#d9534f' },
  iconHover4: { color: '#d9534f' },
  iconHover5: { color: '#d9534f' },
  iconHover6: { color: '#d9534f' },
  iconHover7: { color: '#f0ad4e' },
  iconHover8: { color: '#f0ad4e' },
  iconHover9: { color: '#5cb85c' },
  iconHover10: { color: '#5cb85c' }
});

export const getPropsHoverNPS = (newHover, npsStyle) => {
  switch (true) {
    case newHover <= 1: {
      return npsStyle.iconHover1;
    }
    case newHover <= 2 && newHover > 1: {
      return npsStyle.iconHover2;
    }
    case newHover <= 3 && newHover > 2: {
      return npsStyle.iconHover3;
    }
    case newHover <= 4 && newHover > 3: {
      return npsStyle.iconHover4;
    }
    case newHover <= 5 && newHover > 4: {
      return npsStyle.iconHover5;
    }
    case newHover <= 6 && newHover > 5: {
      return npsStyle.iconHover6;
    }
    case newHover <= 7 && newHover > 6: {
      return npsStyle.iconHover7;
    }
    case newHover <= 8 && newHover > 7: {
      return npsStyle.iconHover8;
    }
    case newHover <= 9 && newHover > 8: {
      return npsStyle.iconHover9;
    }
    case newHover <= 10 && newHover > 9: {
      return npsStyle.iconHover10;
    }
    default:
      break;
  }
};

export const getPropsFilledNPS = (newValue, npsStyle) => {
  switch (true) {
    case newValue <= 1: {
      return npsStyle.iconFilled1;
    }
    case newValue <= 2 && newValue > 1: {
      return npsStyle.iconFilled2;
    }
    case newValue <= 3 && newValue > 2: {
      return npsStyle.iconFilled3;
    }
    case newValue <= 4 && newValue > 3: {
      return npsStyle.iconFilled4;
    }
    case newValue <= 5 && newValue > 4: {
      return npsStyle.iconFilled5;
    }
    case newValue <= 6 && newValue > 5: {
      return npsStyle.iconFilled6;
    }
    case newValue <= 7 && newValue > 6: {
      return npsStyle.iconFilled7;
    }
    case newValue <= 8 && newValue > 7: {
      return npsStyle.iconFilled8;
    }
    case newValue <= 9 && newValue > 8: {
      return npsStyle.iconFilled9;
    }
    case newValue <= 10 && newValue > 9: {
      return npsStyle.iconFilled10;
    }
    default:
      break;
  }
};

export const getPropsHoverSatisfacao = (newHover, npsStyle) => {
  switch (true) {
    case newHover <= 1: {
      return npsStyle.iconHover1;
    }
    case newHover <= 2 && newHover > 1: {
      return npsStyle.iconHover2;
    }
    case newHover <= 3 && newHover > 2: {
      return npsStyle.iconHover3;
    }
    case newHover <= 4 && newHover > 3: {
      return npsStyle.iconHover4;
    }
    case newHover <= 5 && newHover > 4: {
      return npsStyle.iconHover5;
    }
    default:
      break;
  }
};

export const getPropsFilledSatisfacao = (newValue, npsStyle) => {
  switch (true) {
    case newValue <= 1: {
      return npsStyle.iconFilled1;
    }
    case newValue <= 2 && newValue > 1: {
      return npsStyle.iconFilled2;
    }
    case newValue <= 3 && newValue > 2: {
      return npsStyle.iconFilled3;
    }
    case newValue <= 4 && newValue > 3: {
      return npsStyle.iconFilled4;
    }
    case newValue <= 5 && newValue > 4: {
      return npsStyle.iconFilled5;
    }
    default:
      break;
  }
};
