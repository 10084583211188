import styled from "styled-components";

export const G4Container = styled.div`
  width: 325px;
  height: 465px;
  position: fixed;
  background-color: #fff;
  bottom: 3rem;
  right: 7rem;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.22);
  transition: height 0.5s 0.5s, opacity 600ms 0.4s;
  display: ${props => props.size ? 'block' : 'none'};
  border-radius: 10px 10px 10px 10px;
  box-sizing: border-box;
  z-index: 20000;
`;

export const Hours = styled.div`
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
  margin-left: 5px;
  font-family: roboto;
`;

export const G4LogoOpen = styled.button`
  cursor: pointer;
  position: fixed;
  bottom: 3rem;
  right: 7rem;
  border: #fff solid 5px;
  border-radius: 50%;
  padding: 5px;
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) => props.color && `background: ${props.color};`}
`;
