import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "./components/Login.css";
import { getLogoChatURL } from "../../components/logos";
import { regex, telephone } from "./components/regex";
import { FaUser, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {
  G4Form,
  G4LoginImgDiv,
  G4LoginImg,
  G4Button,
  G4InputForm,
  MessageErro,
  ContentInput,
} from "./components/styled";
import { nextPage } from "../../store/page/actions";
import { addIdConversation, loginAction, socketId } from "../../store/user/actions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { apiHttp } from "../../services/api-http";
import conversaAtendida from "../../services/constraints/answeredConversation";
import apiSocket from "../../services/api-socket";
import store from "../../store";
import { addSessionId } from "../../store/conversation/actions";
import conversaNaoAtendida from "../../services/send-message-bot";

const QUEUE = "Queue";
const CONVERSATION = "Conversation";

const Login = () => {
  const [inputFocus, setInputFocus] = useState({
    name: false,
    email: false,
    telephone: false,
  });
  const [logoURL, setLogoURL] = useState(null);
  const config = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { register, handleSubmit, errors, setValue } = useForm({
    // mode: "onChange"
  });
  const styleIcon = {
    transition: "all 0.5s ease-out",
  };

  useEffect(() => {
    function restoreConversation(conversas) {
      if(localStorage.getItem("conversationAttendant") === "true"){
        conversaAtendida(conversas);
        
      } else {
        conversaNaoAtendida(conversas);
      }
      dispatch(socketId(conversas.id_socket_cliente));
      dispatch(loginAction(conversas.cliente.nome, conversas.cliente.email, conversas.cliente.telefone))
      dispatch(addSessionId(conversas.idSessao));
      dispatch(addIdConversation(String(conversas._id)));
    }
    
    apiSocket.on("connect", () => {
      if(localStorage.getItem("IdConversation")){
        apiHttp
        .get(`/v2/conversa/pegaConversasAbertas/${localStorage.getItem("IdConversation")}/${apiSocket.id}`)
        .then((response) => {
          //dispatch(nextPage(CONVERSATION));
          Object.keys(response.data.conversa).length &&
            restoreConversation(response.data.conversa);
          
        }).catch(() => console.error("[RESTAURING CONVERSATION] error restoring conversations"))
      // } else {
      //   localStorage.setItem("IdConversation", iresponse.data._d)
      //   console.log("nao existe aberto")
      }
    })

    
  }, [dispatch])

  useEffect(() => {
    const { nome, email, tel } = queryString.parse(search);

    if (nome) setValue("name", nome);
    if (email) setValue("email", email);
    if (tel) setValue("telephone", tel);
  }, [search, setValue]);

  useEffect(() => {
    getLogoChatURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading chat logo"));
  }, []);

  function login({ name, email, telephone }) {
    dispatch(loginAction(name, email, telephone.replace(/\D+/g, "")));
    if (config.useBot) {
      dispatch(nextPage(CONVERSATION));
    } else {
      dispatch(nextPage(QUEUE));
    }
  }

 
  function modifiedFocusTrue(e) {
    setInputFocus({
      ...inputFocus,
      [e.target.name]: true,
    });
  }

  function modifiedFocusFalse(e) {
    var name = e.target.name;
    var value = e.target.value;
    if (name === "name" && value === "") {
      setInputFocus({
        ...inputFocus,
        [e.target.name]: false,
      });
    } else if (name === "email" && value === "") {
      setInputFocus({
        ...inputFocus,
        [e.target.name]: false,
      });
    } else if (name === "telephone" && value === "") {
      setInputFocus({
        ...inputFocus,
        [e.target.name]: false,
      });
    }
  }

  function maskInputTelephone(value) {
    value = value.replace(/\D/g, ""); //Remove tudo o que não é dígito
    value = value.replace(/^(\d{2})(\d)/g, "($1)$2"); //Coloca parênteses em volta dos dois primeiros dígitos
    value = value.replace(/(\d{5})(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return value;
  }

  return (
    <>
      <G4Form onSubmit={handleSubmit(login)}>
        <G4LoginImgDiv>
          <G4LoginImg src={logoURL} alt="logo" />
        </G4LoginImgDiv>

        <ContentInput border={errors.name ? "red" : "none"}>
          <FaUser
            size="19"
            style={styleIcon}
            color={inputFocus.name ? "#515151" : "#BCBCBC"}
          />
          <G4InputForm
            onFocus={(event) => modifiedFocusTrue(event)}
            onBlur={(event) => modifiedFocusFalse(event)}
            name="name"
            type="text"
            placeholder="Nome"
            ref={register({ required: true })}
          />
        </ContentInput>
        {
          <MessageErro size={errors.name ? "13px" : "0px"}>
            {errors.name ? "Nome é obrigatório" : ""}
          </MessageErro>
        }

        <ContentInput border={errors.email ? "red" : "none"}>
          <MdEmail
            size="19"
            style={styleIcon}
            color={inputFocus.email ? "#515151" : "#BCBCBC"}
          />
          <G4InputForm
            name="email"
            onFocus={(event) => modifiedFocusTrue(event)}
            onBlur={(event) => modifiedFocusFalse(event)}
            type="text"
            placeholder="Email"
            ref={register({
              required: "Email é obrigatório",
              pattern: {
                value: regex,
                message: "Entre com um email válido",
              },
            })}
          />
        </ContentInput>
        {
          <MessageErro size={errors.email ? "13px" : "0px"}>
            {errors.email ? errors.email.message : ""}
          </MessageErro>
        }

        <ContentInput border={errors.telephone ? "red" : "none"}>
          <FaPhoneAlt
            style={styleIcon}
            size="19"
            color={inputFocus.telephone ? "#515151" : "#BCBCBC"}
          />
          <G4InputForm
            name="telephone"
            onFocus={(event) => modifiedFocusTrue(event)}
            onBlur={(event) => modifiedFocusFalse(event)}
            maxLength={14}
            colorFocus={"#000"}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = maskInputTelephone(value);
            }}
            type="text"
            placeholder="Telefone"
            ref={register({
              required: true,
              pattern: {
                value: telephone,
                message: "Entre com um telefone válido",
              },
            })}
          />
        </ContentInput>
        {
          <MessageErro size={errors.telephone ? "13px" : "0px"}>
            {errors.telephone ? "Telefone é obrigatório" : ""}
          </MessageErro>
        }
        <G4Button color={config.color} type="submit">
          Iniciar
        </G4Button>
      </G4Form>
    </>
  );
};

export default Login;