import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .times {
    font-family: 'Roboto';
    font-size: 10px;
  }
`;

export const MessageAudio = styled.div`
  max-width: 255px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .seekbar {
    -webkit-appearance: none; /* Override default CSS styles */
    height: 4px;
    width: 230px; /* Full-width */
    background-color: #fff; /* Grey background */
    outline: none;
    border-radius: 20%;
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  }

  .seekbar::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${(props) => props.color};
    
  }

  .seekbar::-ms-thumb {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${(props) => props.color};
  }
`;
