export function initSurvey(conversationId, survey, active) {
  return {
    type: "@survey/INIT_SURVEY",
    payload: {
      conversationId,
      survey,
      active,
    },
  };
}
