const INITIAL_STATE = {
    name: ''
}

export default function getNameFileReduce(state=INITIAL_STATE, action){
    switch(action.type){
        case 'GET_NAME_FILE':
            //console.log("filtro:",action.payload)
            return action.payload.name
        default:
            return state

    }
}
