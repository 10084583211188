import styled from "styled-components";

export const G4Header = styled.header`
  width: 100%;
  height: 42px;
  border-radius: 10px 10px 0 0;
  display: flex;
  color: white;
  align-items: center;

  ${(props) => props.color && `background: ${props.color};`}

  .minimize {
    margin-left: auto;
    margin-right: 10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  svg {
    cursor: pointer;
  }
`;

export const G4Avatar = styled.img`
  height: 50px;
  margin: -30px 0px 0px 25px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.21);
  background: white;
`;

export const G4HeaderItens = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;

  .circleOnline {
    height: 12px;
    width: 12px;
    background-color: ${props => props.onlineAtendent ? '#c05353' : '#34fe60'};
    border-radius: 50%;
    margin: 0 10px 0 -12px;
  }
`;
