import { apiHttpNotOpen } from "./api-http";

import store from "../store";
import { addConfig } from "../store/config/actions";

apiHttpNotOpen
  .get("api/open/configurations/webchat")
  .then((response) => {
    // const color = 'true';
    const useBot = response.data.data.ativado;
    const botName = response.data.data.nome_atendente;
    const color = response.data.data.cor || "#2C984A";
    // const useBot = false;

    store.dispatch(addConfig(color, useBot, botName));
  })
  .catch((err) => {
    console.log(err);
  });
