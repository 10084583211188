import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./components/End.css";
import { getLogoChatURL } from "../../components/logos";
import {
  G4ContainerEnd,
  G4EndImgDiv,
  G4EndImg,
  G4FooterEnd,
} from "./components/styled";

const End = () => {
  const config = useSelector((state) => state.config);
  const [logoURL, setLogoURL] = useState(null);

  useEffect(() => {
    getLogoChatURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading chat logo"));
  }, []);

  return (
    <G4ContainerEnd>
      <G4EndImgDiv>
        <G4EndImg src={logoURL} alt="logo" />
      </G4EndImgDiv>

      <p>
        Obrigado por entrar em contato conosco, estaremos sempre à disposição!
        😀
      </p>
      <G4FooterEnd color={config.color}></G4FooterEnd>
    </G4ContainerEnd>
  );
};

export default End;
