import styled from 'styled-components';

export const AudioInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .controlBtn {
    font-size: 30px;
    margin: 0;
  }

  .blinker {
    width: 8px;
    //margin: 30px 2px 0 -5px;
    height: 8px;
    background-color: #d9534f;
    border-radius: 50%;
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
`;

export const AudioTime = styled.div`
  font-size: 12px;
  //margin-top: 25px;
`;

export const ButtonAudio = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.8s;
  width: 30px;
  height: 30px;
  border: 0;

  ${(props) => props.color && `background: ${props.color};`}
  
  svg {
    transition: color .25s;
  }
  
  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: 0;
  }
`
