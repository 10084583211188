import io from "socket.io-client";

import conversaAtendida from "./constraints/answeredConversation";
import atendenteEnviaMenssagem from "./constraints/attendantSendMessage";
import encerrarCoversaCliente from "./constraints/endCoversationClient";
import clienteTranferido from "./constraints/tranferredClient";
import entrouFilaCliente from "./http/queueClient";
import responderSurvey from "./constraints/answerSurvey";

import store from "../store";

const SOCKET_IO_URL = `${process.env.REACT_APP_PROXY_URL}public`;
const apiSocket = io(SOCKET_IO_URL, {
  path: "/flex-chat-integration-socket",
});

// const apiSocketOpen = io(SOCKET_IO_URL, {
//   path: "/flex-chat-integration-socket/open",
// });

apiSocket.on("connect", (event) => {
  apiSocket.on("entrou_na_fila_cliente", (event) => {
    entrouFilaCliente(event);
  });

  apiSocket.on("cliente_transferido", (event) => {
    clienteTranferido(event);
  });

  apiSocket.on("conversa_transferida", (event) => {});

  apiSocket.on("conversa_atendida_cliente", (event) => {
    conversaAtendida(event);
  });

  apiSocket.on("atendente_enviou_mensagem", (event) => {
    let now = new Date();

    const newEvent = event?.message?.hora_da_mensagem
      ? [event.message]
      : [{ ...event, hora_da_mensagem: now }];

    atendenteEnviaMenssagem(newEvent);
  });

  apiSocket.on("encerrar_conversa_cliente", (event) => {
    localStorage.removeItem("IdConversation");
    setTimeout(() => {
      encerrarCoversaCliente(event);
    }, 5000);
    localStorage.setItem("conversationAttendant", "false");
  });

  apiSocket.on("send_survey", (event) => {
    responderSurvey(event.conversa_id, event.survey, true);
  });
});

// store.dispatch(loginAction('asdf','qwer@qwe.com'));
export default apiSocket;
