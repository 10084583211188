const INITIAL_STATE = false

export default function hoursOfOut(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@HOURS_OF_OUT':
      return action.payload;
    default:
      return state;
  }
}
