import produce from "immer";

const INITIAL_STATE = {
  conversationId: "",
  survey: {},
  active: false,
};

export default function conversation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@survey/INIT_SURVEY":
      return produce(state, (draft) => {
        draft.conversationId = action.payload.conversationId;
        draft.survey = action.payload.survey;
        draft.active = action.payload.active;
      });
    default:
      return state;
  }
}
