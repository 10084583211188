import styled from 'styled-components';

export const Upload = styled.div`
  height: 50px;
  width: 200px;
  display: flex;
  justify-content: start;
  font-size: 16px;

  a + a {
    margin-top: 10px;
  }

  a {
    color: #f00;
    font-family: Roboto, sans-serif;
    justify-content: center;
    align-items: center;
    display: flex;
    text-decoration: none;
  }

  p {
    font-size: 14px;
    margin-left: 5px;
    word-break: break-all;
  }
`;