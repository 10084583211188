const INITIAL_STATE = 'Login'

export default function page(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@page/NEXT_PAGE':
      return action.payload;
    default:
      return state;
  }
}
